import React from 'react'

export function Button(
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) {
  return (
    <button
      className="px-8 py-1 text-lg bg-green-500 text-white rounded-full disabled:opacity-50"
      {...props}
    />
  )
}
