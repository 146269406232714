import React from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import { useAuth } from 'src/hooks/useAuth'
import firebase from 'firebase/app'

interface HeaderProps {
  title: string
}

export function Header({ title }: HeaderProps) {
  const { currentFBUser, signOut } = useAuth()
  const [isNavbarOpen, setNavbarOpen] = React.useState(false)

  return (
    <>
      <nav className="bg-gray-800 mb-4 px-4">
        <div className="h-12 container m-auto flex items-center justify-between">
          <div className="text-white text-lg">
            <Link to="/">{title}</Link>
          </div>
          <span
            className="text-white text-2xl md:hidden"
            onClick={() => setNavbarOpen(!isNavbarOpen)}
          >
            ≡
          </span>
          <HeaderList currentFBUser={currentFBUser} signOut={signOut} />
        </div>
      </nav>
      {isNavbarOpen && (
        <div className="flex justify-center ">
          <div className="z-10 w-3/4 flex justify-center items-center border fixed p-4 rounded-lg bg-white shadow-md">
            <MobileHeaderList currentFBUser={currentFBUser} signOut={signOut} />
          </div>
        </div>
      )}
    </>
  )
}

export interface HeaderListProps {
  currentFBUser: firebase.User | null
  signOut: () => Promise<void>
}

const HeaderList: React.FC<HeaderListProps> = ({ currentFBUser, signOut }) => {
  return (
    <div className="text-sm text-white hidden md:block">
      {currentFBUser ? (
        <div className="flex flex-row justify-end">
          <div className="mx-4">
            <Link to="/">{t`予約画面`}</Link>
          </div>
          <div className="mx-4">
            <Link to="/admin">{t`ダッシュボード`}</Link>
          </div>
          <div
            onClick={signOut}
            className="cursor-pointer mx-4"
          >{t`ログアウト`}</div>
        </div>
      ) : (
        <Link to="/admin/login">{t`ログイン`}</Link>
      )}
    </div>
  )
}

const MobileHeaderList: React.FC<HeaderListProps> = ({
  currentFBUser,
  signOut,
}) => {
  return (
    <div className="text-sm text-gray-800">
      {currentFBUser ? (
        <div className="flex flex-col items-start">
          <div className="mx-4">
            <Link to="/">{t`予約画面`}</Link>
          </div>
          <div className="mx-4">
            <Link to="/admin">{t`ダッシュボード`}</Link>
          </div>
          <div
            onClick={signOut}
            className="cursor-pointer mx-4"
          >{t`ログアウト`}</div>
        </div>
      ) : (
        <Link to="/admin/login">{t`ログイン`}</Link>
      )}
    </div>
  )
}
