import { t } from 'ttag'
import React from 'react'

interface PaginationProps {
  onPrev: () => void
  onNext: () => void
  canPrev?: boolean
  canNext?: boolean
}

function PaginationButton(
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) {
  return (
    <button
      className="px-4 py-1 text-blue-500 border-blue-200 border border-solid rounded-full"
      {...props}
    />
  )
}

export function Pagination({
  onPrev,
  onNext,
  canPrev = true,
  canNext = true,
}: PaginationProps) {
  return (
    <div className="flex justify-between">
      {canPrev ? (
        <PaginationButton onClick={onPrev}>{t`←先月`}</PaginationButton>
      ) : (
        <span />
      )}
      {canNext && (
        <PaginationButton onClick={onNext}>{t`翌月→`}</PaginationButton>
      )}
    </div>
  )
}
