import clsx from 'clsx'
import {
  weekdays,
  getWeekdayColorClass,
  getWeekdayString,
} from 'src/shared/util'

export function CalendarHead() {
  return (
    <thead>
      <tr>
        {weekdays.map((day) => (
          <th
            key={day}
            className={clsx('border boder-solid', getWeekdayColorClass(day))}
          >
            {getWeekdayString(day)}
          </th>
        ))}
      </tr>
    </thead>
  )
}
