import ahooksUseUrlState from '@ahooksjs/use-url-state'

export function useUrlState<T>(defaultValue: T) {
  const urlState = ahooksUseUrlState<T>(defaultValue)
  // @ts-ignore
  const state: T = urlState[0]
  // @ts-ignore
  const setState: (val: T) => void = urlState[1]

  return [state, setState] as const
}
