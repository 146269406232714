import { useHistory } from 'react-router-dom'
import { t } from 'ttag'
import { gql } from 'urql'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppContext, ReservationForm } from 'src/AppContext'
import { InputWithLabel } from 'src/components/InputWithLabel'
import { Button } from 'src/components/Button'
import { formatDateForHuman } from 'src/shared/util'
import * as R from 'remeda'
import { RequiredMark } from 'src/components/RequiredMark'
import { useGetEquipmentsQuery } from 'src/__generated__/gql'

gql`
  query GetEquipments($targetDate: String!) {
    equipments(targetDate: $targetDate) {
      id
      price
      name
      type
    }
  }
`

export function Input() {
  const history = useHistory()
  const appContext = useAppContext()
  const [reservations] = useState(appContext.state.reservations)
  const [{ data, fetching }] = useGetEquipmentsQuery({
    variables: {
      targetDate: appContext.state.reservations[0].dateOfUse,
    },
  })
  const { register, handleSubmit } = useForm({
    defaultValues: {
      user: appContext.state.user,
      reservations: appContext.state.reservations,
    },
  })

  const onSubmit = handleSubmit((input) => {
    const values: ReservationForm[] = Object.values(input.reservations)
    appContext.setState({
      user: input.user,
      reservations: values.map((value) => ({
        id: R.randomString(4),
        dateOfUse: value.dateOfUse,
        equipmentIds: value.equipmentIds,
        timeSlotId: value.timeSlotId,
        timeSlotName: value.timeSlotName,
      })),
    })
    history.push('/reservations/confirm')
  })

  return (
    <div className="container m-auto p-4">
      <h1 className="text-2xl">{t`利用情報の入力`}</h1>
      <form onSubmit={onSubmit}>
        <div className="container">
          <div className="mt-4">
            <div className="font-bold">{t`利用日`}</div>
            {reservations.map((reservation) => (
              <li key={reservation.id}>
                {formatDateForHuman(reservation.dateOfUse)}{' '}
                {reservation.timeSlotName}
              </li>
            ))}
          </div>
          <div className="mt-4">
            <div className="font-bold">{t`利用設備`}</div>
            <div>
              <p>{t`ご利用日から一週間以内のご予約の場合はご利用になれません。`}</p>
              <p>{t`使用料は以下の通りです。`}</p>
              {data?.equipments.map((equipment) => (
                <li key={equipment.id}>
                  {t`${equipment.name}：${equipment.price}円/${
                    equipment.type === 'BY_TIME_SLOT' ? 'コマ' : '日'
                  }`}
                </li>
              ))}
            </div>
            {reservations.map((reservation) => (
              <div key={reservation.id} className="mt-2">
                <div>
                  {formatDateForHuman(reservation.dateOfUse)}{' '}
                  {reservation.timeSlotName}
                </div>
                <input
                  type="hidden"
                  name={`reservations.${reservation.id}.dateOfUse`}
                  value={reservation.dateOfUse}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={`reservations.${reservation.id}.timeSlotId`}
                  value={reservation.timeSlotId}
                  ref={register}
                />
                <input
                  type="hidden"
                  name={`reservations.${reservation.id}.timeSlotName`}
                  value={reservation.timeSlotName}
                  ref={register}
                />
                {fetching && <div className="">読み込み中...</div>}
                {data?.equipments.map((equipment) => (
                  <div key={equipment.id}>
                    <label className="ml-4">
                      <input
                        value={equipment.id}
                        type="checkbox"
                        name={`reservations.${reservation.id}.equipmentIds[]`}
                        ref={register}
                      />
                      <span className="ml-2">{equipment.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <InputWithLabel
            name="user.group"
            label={t`団体名`}
            placeholder="XX教室"
            ref={register()}
          />
          <InputWithLabel
            name="user.username"
            label={t`代表者名`}
            placeholder="田中 太郎"
            ref={register({ required: true })}
            required
          />
          <InputWithLabel
            name="user.tel"
            label={t`電話番号`}
            placeholder={t`080-XXXX-YYYY`}
            ref={register({ required: true })}
            required
          />
          <InputWithLabel
            name="user.email"
            label={t`メールアドレス`}
            placeholder="you@example.com"
            ref={register({ required: true })}
            required
          />
          <InputWithLabel
            name="user.address"
            label={t`ご住所`}
            placeholder="東京都渋谷区渋谷2丁目19-15宮益坂ビルディング609"
            ref={register({ required: true })}
            required
          />
          <InputWithLabel
            name="user.purpose"
            label="利用目的"
            placeholder="XXのため"
            ref={register({ required: true })}
            required
          />
          <div className="mt-4">
            <div className="font-bold">
              {t`お支払い方法`}
              <RequiredMark />
            </div>
            <div className="ml-4">
              <label>
                <input
                  type="radio"
                  name="user.paymentMethod"
                  value="BANK"
                  ref={register()}
                />
                <span className="ml-2">{t`銀行振込`}</span>
              </label>
            </div>
          </div>
          <InputWithLabel
            name="user.remarks"
            label={t`備考`}
            placeholder={t`楽器を使う方は、使用する楽器を記入してください`}
            multiline
            ref={register()}
          />
          <div className="text-center m-8">
            <Button disabled={fetching} type="submit">{t`次へ`}</Button>
          </div>
        </div>
      </form>
    </div>
  )
}
