import { addLocale, useLocale as changeLocale, setDefaultLang } from 'ttag'

const LOCALE_KEY = 'reservationSystem__locale'

type Locale = keyof typeof localeMap

export const localeMap = {
  en: {
    label: 'English',
    getTranslations: () => import('src/shared/locales/en.json'),
  },
  ja: {
    label: '日本語',
    getTranslations: () => import('src/shared/locales/ja.json'),
  },
}

setDefaultLang('ja')

export async function changeLocaleAsync(lc: Locale) {
  const getLocaleObj = localeMap[lc].getTranslations
  if (!getLocaleObj) {
    throw new Error(`no such locale: ${lc}`)
  }
  const localeObj = await getLocaleObj()
  addLocale(lc, localeObj.default)
  changeLocale(lc)
  localStorage.setItem(LOCALE_KEY, lc)
  window.location.reload()
}
