import { useForm } from 'react-hook-form'
import { Loading } from 'src/components/Loading'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { gql } from 'urql'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { t } from 'ttag'
import { useGetReservationListQuery } from 'src/__generated__/gql'
import { useCalendarize } from 'src/hooks/useCalendarize'
import { Closed } from 'src/components/Closed'
import { NotEligible } from 'src/components/NotEligible'
import { Pagination } from 'src/components/Pagination'
import { CalendarHead } from 'src/components/CalendarHead'
import { Button } from 'src/components/Button'
import { useAppContext } from 'src/AppContext'
import {
  getWeekdayColorClass,
  getWeekdayFromNumber,
  DATE_FORMAT,
} from 'src/shared/util'
import * as R from 'remeda'

gql`
  query GetReservationList($year: Int!, $month: Int!) {
    reservations(year: $year, month: $month, isDeleted: false) {
      id
      dateOfUse
      timeSlot {
        id
      }
    }
    timeSlots {
      id
      name
    }
  }
`

export function Calendar() {
  const history = useHistory()
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      dates: [] as string[],
    },
  })

  const appContext = useAppContext()

  const {
    currentMonth,
    year,
    month,
    weeks,
    nextMonth,
    prevMonth,
  } = useCalendarize()

  const [{ data, fetching }] = useGetReservationListQuery({
    variables: { year, month },
  })

  const includes = useCallback(
    (date: number, timeSlotId: string) => {
      const _date = currentMonth.date(date)
      if (_date.isBefore(new Date())) {
        return true
      }
      const index = data?.reservations.findIndex((r) => {
        return (
          // prettier-ignore
          dayjs(r.dateOfUse).format(DATE_FORMAT) === _date.format(DATE_FORMAT) &&
          r.timeSlot.id === timeSlotId
        )
      })
      return index !== -1
    },
    [data, currentMonth],
  )

  const notEligible = useCallback(
    (date: number) => {
      const _date = currentMonth.date(date)
      return _date.isBefore(dayjs().add(7, 'day'))
    },
    [currentMonth],
  )

  const onSubmit = handleSubmit((input) => {
    appContext.setState({
      reservations: input.dates.map((date) => {
        const [dateOfUse, timeSlotId] = date.split(',')
        return {
          id: R.randomString(4),
          dateOfUse: currentMonth.date(Number(dateOfUse)).format('YYYY-MM-DD'),
          timeSlotId,
          timeSlotName: data?.timeSlots.find((t) => t.id === timeSlotId)?.name!,
          equipmentIds: [],
        }
      }),
    })
    history.push('/reservations/input')
    window.scrollTo(0, 0)
  })

  const canPrev = useMemo(() => currentMonth.isAfter(new Date(), 'month'), [
    currentMonth,
  ])

  const canNext = useMemo(
    () => currentMonth.add(-5, 'month').isBefore(new Date(), 'month'),
    [currentMonth],
  )

  const isSelected = watch('dates').length > 0

  return (
    <div className="container m-auto p-4">
      <h1 className="text-2xl">{t`予約日時を選択`}</h1>
      <div className="mt-2">{t`ご利用方法`}</div>
      <div className="mt-2">
        <div>{t` 【お申込み方法】 `}</div>
        {t`ご利用を希望されるコマにチェックしてページ下の「次に進む」ボタンをクリックしてください。`}
        <li>{t` 必要事項を入力してください。 `}</li>
        <li>{t` 入力内容をご確認ください。 `}</li>
        <li>{t` 予約確認メールが届いたことをご確認ください。 `}</li>
        <div>
          <li className="list-none">
            {t`
            ※メールアドレスを入力される際に、お間違えのないようご確認ください。
          `}
          </li>
          <li className="list-none">
            {t`
            ※予約確認メールが届かない場合は、machi.info@gmail.comまでご連絡ください。`}
          </li>
        </div>
        {t` 【カレンダーの見方】 `}
        <div>
          <Closed />
          {t` が付いているコマは既にご予約をいただいており、お申し込みができないコマです。 `}
        </div>
        <div>
          <NotEligible />
          {t` が付いているコマは、webからのご予約はできませんが、空いておりますので、
          プラムジャム(KFまちかどホールの隣の施設、受付時間:月、火、木、金13時〜17時)までお越しください。 `}
        </div>
      </div>
      <div className="my-6">
        <Pagination
          onPrev={prevMonth}
          onNext={nextMonth}
          canPrev={canPrev}
          canNext={canNext}
        />
      </div>
      <div className="my-4 text-center text-lg">
        {currentMonth.format('YYYY年M月')}
      </div>
      {fetching && (
        <div className="h-0">
          <Loading />
        </div>
      )}

      <form onSubmit={onSubmit}>
        <div className="overflow-x-auto">
          <table className="md:min-w-full border border-solid min-w-max">
            <CalendarHead />
            <tbody>
              {weeks.map((week) => (
                <tr key={String(week)}>
                  {week.map((date, i) => (
                    <td
                      key={i}
                      className={clsx(
                        'p-3 border boder-solid',
                        getWeekdayColorClass(getWeekdayFromNumber(i)),
                      )}
                    >
                      {date > 0 && (
                        <>
                          <div>{date}</div>
                          {data?.timeSlots.map((timeSlot) => (
                            <div key={timeSlot.id}>
                              <label>
                                {includes(date, timeSlot.id) ? (
                                  <Closed />
                                ) : notEligible(date) ? (
                                  <NotEligible />
                                ) : (
                                  <input
                                    name="dates[]"
                                    type="checkbox"
                                    value={[String(date), timeSlot.id]}
                                    ref={register}
                                  />
                                )}
                                <span className="ml-3">{timeSlot.name}</span>
                              </label>
                            </div>
                          ))}
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-center my-6">
          <Button
            type="submit"
            disabled={fetching || !isSelected}
          >{t`次へ`}</Button>
        </div>
      </form>
    </div>
  )
}
