import React from 'react'
import { RequiredMark } from './RequiredMark'

interface InputWithLabelProps {
  label: string
  name: string
  placeholder?: string
  multiline?: boolean
  type?: string
  required?: boolean
}

export const InputWithLabel = React.forwardRef<any, InputWithLabelProps>(
  (
    { label, name, placeholder = '', multiline, type = 'text', required },
    ref,
  ) => {
    return (
      <div className="mt-4">
        <label className="font-bold">
          <div>
            <span>{label}</span>
            {required && <RequiredMark />}
          </div>
          {multiline ? (
            <textarea
              className="border border-solid border-gray-300 px-3 py-1 rounded text-gray-800 w-full"
              name={name}
              placeholder={placeholder}
              ref={ref}
            />
          ) : (
            <input
              className="border border-solid border-gray-300 px-3 py-1 rounded text-gray-800 w-full"
              name={name}
              placeholder={placeholder}
              ref={ref}
              type={type}
            />
          )}
        </label>
      </div>
    )
  },
)
