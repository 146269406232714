import { Switch, Route, useHistory } from 'react-router-dom'
import { Header } from 'src/components/Header'
import { useEffect } from 'react'
import { Reservations } from './reservations'
import { ReservationId } from './reservation-id'
import { Login } from './login'
import { useAuth } from 'src/hooks/useAuth'
import { Loading } from 'src/components/Loading'

export function Admin() {
  const { loading, currentFBUser } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (!loading && !currentFBUser) {
      return history.push('/admin/login')
    }
  }, [currentFBUser, history, loading])

  useEffect(() => {
    if (currentFBUser && history.location.pathname === '/admin/login') {
      return history.push('/admin')
    }
  }, [currentFBUser, history, loading])

  if (loading) {
    return (
      <div>
        <Header title="Admin" />
        <div className="container m-auto">
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header title="Admin" />
      <div className="container m-auto p-3 md:px-8">
        <Switch>
          <Route exact path="/admin" component={Reservations} />
          <Route exact path="/admin/login" component={Login} />
          <Route exact path="/admin/reservations" component={Reservations} />
          <Route
            exact
            path="/admin/reservations/:id"
            component={ReservationId}
          />
        </Switch>
      </div>
    </div>
  )
}
