import firebase from 'firebase/app'
import { useCallback, useEffect, useState } from 'react'

export const useAuth = () => {
  const [currentFBUser, setCurrentFBUser] = useState<firebase.User | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setCurrentFBUser(user)
      setLoading(false)
    })
  }, [setCurrentFBUser])

  // For development purpose only
  const signIn = useCallback((email: string, password: string) => {
    setLoading(true)
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setLoading(false))
  }, [])

  // For development purpose only
  const signOut = useCallback(() => {
    setLoading(true)
    return firebase
      .auth()
      .signOut()
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setLoading(false))
  }, [])

  return {
    currentFBUser,
    loading,
    error,
    signIn,
    signOut,
  }
}
