import { useMemo } from 'react'
import calendarize from 'calendarize'
import dayjs from 'dayjs'
import { useUrlState } from './useUrlState'

type YM = { year: string; month: string }

function nextYM({ year, month }: YM): YM {
  if (month === '12') {
    return { year: String(Number(year) + 1), month: '1' }
  }
  return { year, month: String(Number(month) + 1) }
}

function prevYM({ year, month }: YM): YM {
  if (month === '1') {
    return { year: String(Number(year) - 1), month: '12' }
  }
  return { year, month: String(Number(month) - 1) }
}

export function useCalendarize() {
  const [state, setState] = useUrlState({
    month: String(new Date().getMonth() + 1),
    year: String(new Date().getFullYear()),
  })
  const year = Number(state.year)
  const month = Number(state.month)

  const currentMonth = useMemo(
    () =>
      dayjs()
        .year(year)
        .month(month - 1),
    [year, month],
  )

  const weeks = useMemo(() => calendarize(currentMonth.toDate()), [
    currentMonth,
  ])

  const nextMonth = () => setState(nextYM(state))
  const prevMonth = () => setState(prevYM(state))

  return { weeks, currentMonth, nextMonth, prevMonth, month, year }
}
