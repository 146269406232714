import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BlockReservationInput = {
  dateOfUse: Scalars['String'];
  timeSlotId: Scalars['String'];
};

export type EmailHistory = {
  __typename?: 'EmailHistory';
  content: Scalars['String'];
  id: Scalars['String'];
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  type: EquipmentType;
};

export type EquipmentType =
  | 'BY_DATE'
  | 'BY_TIME_SLOT';

export type Mutation = {
  __typename?: 'Mutation';
  blockTimeSlots: Scalars['Boolean'];
  createReservations: EmailHistory;
  updateReservation: Reservation;
};


export type MutationBlockTimeSlotsArgs = {
  input: Array<BlockReservationInput>;
};


export type MutationCreateReservationsArgs = {
  input: Array<ReservationInput>;
};


export type MutationUpdateReservationArgs = {
  id: Scalars['ID'];
  input: ReservationInput;
};

export type PaymentMethod =
  | 'BANK'
  | 'CASH';

export type PricePreview = {
  __typename?: 'PricePreview';
  equipments: Array<PricePreviewEquipment>;
  timeSlots: Array<PricePreviewTimeSlot>;
  totalPrice: Scalars['Int'];
};

export type PricePreviewEquipment = {
  __typename?: 'PricePreviewEquipment';
  count: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  totalPrice: Scalars['Int'];
  type: EquipmentType;
  unitPrice: Scalars['Int'];
};

export type PricePreviewTimeSlot = {
  __typename?: 'PricePreviewTimeSlot';
  dateOfUse: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Int'];
  timeSlot: TimeSlot;
};

export type Query = {
  __typename?: 'Query';
  emailHistory: EmailHistory;
  equipments: Array<Equipment>;
  pricePreview: PricePreview;
  reservation?: Maybe<Reservation>;
  reservations: Array<Reservation>;
  settings: Settings;
  timeSlots: Array<TimeSlot>;
};


export type QueryEmailHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryEquipmentsArgs = {
  targetDate: Scalars['String'];
};


export type QueryPricePreviewArgs = {
  input: Array<ReservationInput>;
};


export type QueryReservationArgs = {
  id: Scalars['ID'];
};


export type QueryReservationsArgs = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  dateOfUse: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  equipmentIds: Array<Scalars['String']>;
  equipments: Array<Equipment>;
  group: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  paymentMethod: PaymentMethod;
  purpose: Scalars['String'];
  remarks: Scalars['String'];
  tel: Scalars['String'];
  timeSlot: TimeSlot;
  timeSlotId: Scalars['String'];
  updatedAt: Scalars['String'];
  username: Scalars['String'];
};

export type ReservationInput = {
  address: Scalars['String'];
  dateOfUse: Scalars['String'];
  email: Scalars['String'];
  equipmentIds: Array<Scalars['ID']>;
  group: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  paymentMethod: PaymentMethod;
  purpose: Scalars['String'];
  remarks: Scalars['String'];
  tel: Scalars['String'];
  timeSlotId: Scalars['String'];
  username: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  termsOfUse: Scalars['String'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  holidayPrice: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  weekdayPrice: Scalars['Int'];
};

export type FindReservationByAdminQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindReservationByAdminQuery = { __typename?: 'Query', reservation?: { __typename?: 'Reservation', createdAt: string, dateOfUse: string, email: string, group: string, id: string, isDeleted: boolean, paymentMethod: PaymentMethod, address?: string | null, purpose: string, remarks: string, tel: string, username: string, equipments: Array<{ __typename?: 'Equipment', id: string, name: string }>, timeSlot: { __typename?: 'TimeSlot', id: string, name: string } } | null };

export type UpdateReservationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ReservationInput;
}>;


export type UpdateReservationMutation = { __typename?: 'Mutation', updateReservation: { __typename?: 'Reservation', id: string } };

export type GetReservationListByAdminQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type GetReservationListByAdminQuery = { __typename?: 'Query', reservations: Array<{ __typename?: 'Reservation', createdAt: string, dateOfUse: string, deletedAt?: string | null, email: string, group: string, id: string, paymentMethod: PaymentMethod, purpose: string, isDeleted: boolean, remarks: string, tel: string, username: string, equipments: Array<{ __typename?: 'Equipment', id: string, name: string }>, timeSlot: { __typename?: 'TimeSlot', id: string, name: string } }>, timeSlots: Array<{ __typename?: 'TimeSlot', id: string, name: string }> };

export type BlockTimeSlotsMutationVariables = Exact<{
  input: Array<BlockReservationInput> | BlockReservationInput;
}>;


export type BlockTimeSlotsMutation = { __typename?: 'Mutation', blockTimeSlots: boolean };

export type GetReservationListQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type GetReservationListQuery = { __typename?: 'Query', reservations: Array<{ __typename?: 'Reservation', id: string, dateOfUse: string, timeSlot: { __typename?: 'TimeSlot', id: string } }>, timeSlots: Array<{ __typename?: 'TimeSlot', id: string, name: string }> };

export type GetPricePreviewQueryVariables = Exact<{
  input: Array<ReservationInput> | ReservationInput;
}>;


export type GetPricePreviewQuery = { __typename?: 'Query', pricePreview: { __typename?: 'PricePreview', totalPrice: number, timeSlots: Array<{ __typename?: 'PricePreviewTimeSlot', id: string, dateOfUse: string, price: number, timeSlot: { __typename?: 'TimeSlot', id: string, name: string } }>, equipments: Array<{ __typename?: 'PricePreviewEquipment', id: string, name: string, type: EquipmentType, count: number, unitPrice: number, totalPrice: number }> }, settings: { __typename?: 'Settings', termsOfUse: string } };

export type CreateReservationsMutationVariables = Exact<{
  input: Array<ReservationInput> | ReservationInput;
}>;


export type CreateReservationsMutation = { __typename?: 'Mutation', createReservations: { __typename?: 'EmailHistory', id: string } };

export type GetEquipmentsQueryVariables = Exact<{
  targetDate: Scalars['String'];
}>;


export type GetEquipmentsQuery = { __typename?: 'Query', equipments: Array<{ __typename?: 'Equipment', id: string, price: number, name: string, type: EquipmentType }> };

export type GetThanksEmailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetThanksEmailQuery = { __typename?: 'Query', emailHistory: { __typename?: 'EmailHistory', id: string, content: string } };


export const FindReservationByAdminDocument = gql`
    query FindReservationByAdmin($id: ID!) {
  reservation(id: $id) {
    createdAt
    dateOfUse
    email
    equipments {
      id
      name
    }
    group
    id
    isDeleted
    dateOfUse
    paymentMethod
    address
    purpose
    remarks
    tel
    timeSlot {
      id
      name
    }
    username
  }
}
    `;

export function useFindReservationByAdminQuery(options: Omit<Urql.UseQueryArgs<FindReservationByAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<FindReservationByAdminQuery>({ query: FindReservationByAdminDocument, ...options });
};
export const UpdateReservationDocument = gql`
    mutation UpdateReservation($id: ID!, $input: ReservationInput!) {
  updateReservation(id: $id, input: $input) {
    id
  }
}
    `;

export function useUpdateReservationMutation() {
  return Urql.useMutation<UpdateReservationMutation, UpdateReservationMutationVariables>(UpdateReservationDocument);
};
export const GetReservationListByAdminDocument = gql`
    query GetReservationListByAdmin($year: Int!, $month: Int!) {
  reservations(year: $year, month: $month) {
    createdAt
    dateOfUse
    deletedAt
    email
    equipments {
      id
      name
    }
    group
    id
    dateOfUse
    paymentMethod
    purpose
    isDeleted
    remarks
    tel
    timeSlot {
      id
      name
    }
    username
  }
  timeSlots {
    id
    name
  }
}
    `;

export function useGetReservationListByAdminQuery(options: Omit<Urql.UseQueryArgs<GetReservationListByAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReservationListByAdminQuery>({ query: GetReservationListByAdminDocument, ...options });
};
export const BlockTimeSlotsDocument = gql`
    mutation BlockTimeSlots($input: [BlockReservationInput!]!) {
  blockTimeSlots(input: $input)
}
    `;

export function useBlockTimeSlotsMutation() {
  return Urql.useMutation<BlockTimeSlotsMutation, BlockTimeSlotsMutationVariables>(BlockTimeSlotsDocument);
};
export const GetReservationListDocument = gql`
    query GetReservationList($year: Int!, $month: Int!) {
  reservations(year: $year, month: $month, isDeleted: false) {
    id
    dateOfUse
    timeSlot {
      id
    }
  }
  timeSlots {
    id
    name
  }
}
    `;

export function useGetReservationListQuery(options: Omit<Urql.UseQueryArgs<GetReservationListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReservationListQuery>({ query: GetReservationListDocument, ...options });
};
export const GetPricePreviewDocument = gql`
    query GetPricePreview($input: [ReservationInput!]!) {
  pricePreview(input: $input) {
    totalPrice
    timeSlots {
      id
      dateOfUse
      timeSlot {
        id
        name
      }
      price
    }
    equipments {
      id
      name
      type
      count
      unitPrice
      totalPrice
    }
  }
  settings {
    termsOfUse
  }
}
    `;

export function useGetPricePreviewQuery(options: Omit<Urql.UseQueryArgs<GetPricePreviewQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPricePreviewQuery>({ query: GetPricePreviewDocument, ...options });
};
export const CreateReservationsDocument = gql`
    mutation CreateReservations($input: [ReservationInput!]!) {
  createReservations(input: $input) {
    id
  }
}
    `;

export function useCreateReservationsMutation() {
  return Urql.useMutation<CreateReservationsMutation, CreateReservationsMutationVariables>(CreateReservationsDocument);
};
export const GetEquipmentsDocument = gql`
    query GetEquipments($targetDate: String!) {
  equipments(targetDate: $targetDate) {
    id
    price
    name
    type
  }
}
    `;

export function useGetEquipmentsQuery(options: Omit<Urql.UseQueryArgs<GetEquipmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEquipmentsQuery>({ query: GetEquipmentsDocument, ...options });
};
export const GetThanksEmailDocument = gql`
    query GetThanksEmail($id: ID!) {
  emailHistory(id: $id) {
    id
    content
  }
}
    `;

export function useGetThanksEmailQuery(options: Omit<Urql.UseQueryArgs<GetThanksEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<GetThanksEmailQuery>({ query: GetThanksEmailDocument, ...options });
};