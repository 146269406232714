import React from 'react'
import { gql } from 'urql'
import { useParams } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import { useGetThanksEmailQuery } from 'src/__generated__/gql'
import { Loading } from 'src/components/Loading'

gql`
  query GetThanksEmail($id: ID!) {
    emailHistory(id: $id) {
      id
      content
    }
  }
`

export function Thanks() {
  const { emailHistoryId } = useParams<{ emailHistoryId: string }>()

  const [{ data }] = useGetThanksEmailQuery({
    variables: { id: emailHistoryId },
  })

  if (!data) {
    return <Loading />
  }

  return (
    <div className="container m-auto p-4">
      <h1 className="text-2xl">{t`ありがとうございます。`}</h1>
      <div className="mt-2">{t`ご予約ありがとうございます。登録したメールアドレスに確認メールを下記の内容でお送りしましたので、ご確認をお願いします。`}</div>
      <div
        className="p-4 mt-4 bg-gray-200 dont-break-out"
        dangerouslySetInnerHTML={{ __html: data?.emailHistory.content || '' }}
      />
      <div className="text-center m-8">
        <Link to="/">
          <Button>{t`トップに戻る`}</Button>
        </Link>
      </div>
    </div>
  )
}
