import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/Button'
import { useAppContext } from 'src/AppContext'
import { Loading } from 'src/components/Loading'
import {
  equipmentTypeMap,
  paymentMethodMap,
  formatDateForHuman,
} from 'src/shared/util'
import { t } from 'ttag'
import { gql } from 'urql'
import {
  useGetPricePreviewQuery,
  useCreateReservationsMutation,
  GetPricePreviewQueryVariables,
} from 'src/__generated__/gql'

gql`
  query GetPricePreview($input: [ReservationInput!]!) {
    pricePreview(input: $input) {
      totalPrice
      timeSlots {
        id
        dateOfUse
        timeSlot {
          id
          name
        }
        price
      }
      equipments {
        id
        name
        type
        count
        unitPrice
        totalPrice
      }
    }
    settings {
      termsOfUse
    }
  }
`

gql`
  mutation CreateReservations($input: [ReservationInput!]!) {
    createReservations(input: $input) {
      id
    }
  }
`

function Row({ label, value }: { label: string; value?: any }) {
  return (
    <div>
      <div className="font-bold mt-4">{label}</div>
      <div>{value}</div>
    </div>
  )
}

export function Confirm() {
  const history = useHistory()
  const { state } = useAppContext()
  const variables: GetPricePreviewQueryVariables = {
    input: state.reservations.map((reservation) => ({
      ...state.user,
      dateOfUse: reservation.dateOfUse,
      equipmentIds: reservation.equipmentIds,
      timeSlotId: reservation.timeSlotId,
    })),
  }
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      tosAccepted: false,
      cashAccepted: false,
    },
    mode: 'onBlur',
  })
  const [{ data }] = useGetPricePreviewQuery({
    variables,
  })
  const [{ fetching: sending }, execute] = useCreateReservationsMutation()

  const onSubmit = handleSubmit(async () => {
    const { error, data } = await execute(variables)
    if (error) {
      if (error.message.includes('duplicated')) {
        alert(
          t`既に他の人に予約されました。お手数ですが、初めからやり直してください。`,
        )
      } else if (error.message.includes('Out of reservation range')) {
        alert(t`予約できない期間が含まれています。`)
      } else {
        alert(error)
      }
      return
    }
    if (!data) {
      alert(t`エラーが発生しました。`)
      return
    }
    history.push(`/reservations/thanks/${data.createReservations.id}`)
  })

  if (!data) {
    return <Loading />
  }

  return (
    <div className="container m-auto p-4">
      <h1 className="text-2xl">{t`予約内容の確認`}</h1>

      <Row
        label={t`合計金額`}
        value={t`${data.pricePreview.totalPrice.toLocaleString()}円`}
      />

      <Row label={t`ホール利用料金`} />
      {data.pricePreview.timeSlots.map((timeSlot) => (
        <div key={timeSlot.id}>
          <span className="text-bold w-44 inline-block">
            {formatDateForHuman(timeSlot.dateOfUse)} {timeSlot.timeSlot.name}
          </span>
          <span className="">{timeSlot.price.toLocaleString()}円</span>
        </div>
      ))}

      <Row label={t`設備料金`} />
      {data.pricePreview.equipments.length === 0 && (
        <div>{t`設備利用はありません。`}</div>
      )}
      {data.pricePreview.equipments.map((equipment) => (
        <div key={equipment.id}>
          <span className="text-bold w-36 inline-block">{equipment.name}</span>
          <span className="">
            {equipment.unitPrice.toLocaleString()}円 x {equipment.count}{' '}
            {equipmentTypeMap.get(equipment.type)}
          </span>
        </div>
      ))}
      <Row label={t`団体名`} value={state.user.group} />
      <Row label={t`代表者名`} value={state.user.username} />
      <Row label={t`電話番号`} value={state.user.tel} />
      <Row label={t`メールアドレス`} value={state.user.email} />
      <Row label={t`住所`} value={state.user.address} />
      <Row label={t`利用目的`} value={state.user.purpose} />
      <Row label={t`備考`} value={state.user.remarks} />
      <Row
        label={t`お支払い方法`}
        value={paymentMethodMap.get(state.user.paymentMethod)}
      />

      <div className="max-h-64 overflow-y-scroll border border-solid p-2 mt-4">
        {data.settings.termsOfUse}
      </div>
      <form onSubmit={onSubmit}>
        <div className="my-3">
          <label>
            <input
              name="tosAccepted"
              type="checkbox"
              className="mr-2"
              ref={register({ required: true })}
            />
            {t`私は、上記の規約を正確に理解し、また承諾したことをここに証します。`}
          </label>
        </div>
        {errors.tosAccepted && (
          <div className="bg-red-200 text-red-600">{t`利用規約への同意が必要です`}</div>
        )}

        {state.user.paymentMethod === 'CASH' && (
          <div className="my-3">
            <label>
              <input
                name="cashAccepted"
                type="checkbox"
                className="mr-2"
                ref={register({ required: true })}
              />
              {t`私は、現金での支払いの際は、プラムジャムが釣り銭をお渡しすることができないことを理解し、利用料金を釣り銭の無いように用意します。`}
            </label>
          </div>
        )}
        {errors.cashAccepted && (
          <div className="bg-red-200 text-red-600">{t`現金でのお支払いに関する同意が必要です`}</div>
        )}

        <div className="text-center m-8">
          <Button type="submit">
            {sending ? t`送信しています...` : t`予約を確定する`}
          </Button>
        </div>
      </form>
    </div>
  )
}
