import { gql } from 'urql'
import { Loading } from 'src/components/Loading'
import { Button } from 'src/components/Button'
import { InputWithLabel } from 'src/components/InputWithLabel'
import { formatDateForHuman } from 'src/shared/util'
import { t } from 'ttag'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import {
  ReservationInput,
  GetEquipmentsQuery,
  useFindReservationByAdminQuery,
  useGetEquipmentsQuery,
  useUpdateReservationMutation,
} from 'src/__generated__/gql'
import toast from 'react-hot-toast'

gql`
  query FindReservationByAdmin($id: ID!) {
    reservation(id: $id) {
      createdAt
      dateOfUse
      email
      equipments {
        id
        name
      }
      group
      id
      isDeleted
      dateOfUse
      paymentMethod
      address
      purpose
      remarks
      tel
      timeSlot {
        id
        name
      }
      username
    }
  }
`

gql`
  mutation UpdateReservation($id: ID!, $input: ReservationInput!) {
    updateReservation(id: $id, input: $input) {
      id
    }
  }
`

export function ReservationId() {
  const { id } = useParams<{ id: string }>()

  const [{ data, fetching }] = useFindReservationByAdminQuery({
    variables: { id },
  })

  const dateOfUse = data?.reservation?.dateOfUse

  const [{ fetching: updating }, update] = useUpdateReservationMutation()
  const [{ data: equipmentData }] = useGetEquipmentsQuery({
    variables: {
      targetDate: data?.reservation?.dateOfUse as string,
    },
    pause: dateOfUse === undefined,
  })

  const onSubmit = async (input: ReservationInput) => {
    const { error } = await update({
      id,
      input,
    })
    if (!error) {
      toast.success('予約情報を更新しました。')
    }
  }

  if (fetching) {
    return <Loading />
  }

  if (!data?.reservation) {
    return <div>Not Found</div>
  }

  const defaultValues: ReservationInput = {
    ...data.reservation,
    address: data.reservation.address || '',
    equipmentIds: data.reservation.equipments.map((e) => e.id),
    timeSlotId: data.reservation.timeSlot.id,
  }

  return (
    <div>
      <div>
        {formatDateForHuman(data.reservation.dateOfUse)}
        {data.reservation.timeSlot.name}
      </div>

      <ReservationForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        equipments={equipmentData?.equipments || []}
        updating={updating}
      />
    </div>
  )
}

interface ReservationFormProps {
  defaultValues: ReservationInput
  equipments: GetEquipmentsQuery['equipments']
  onSubmit: (values: ReservationInput) => Promise<any>
  updating: boolean
}

export function ReservationForm({
  defaultValues,
  onSubmit,
  equipments,
  updating,
}: ReservationFormProps) {
  const { register, handleSubmit } = useForm({
    defaultValues,
  })

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* TODO */}
        <input type="hidden" name="dateOfUse" ref={register()} />
        <input type="hidden" name="timeSlotId" ref={register()} />

        <InputWithLabel label="団体名" name="group" ref={register()} />
        <InputWithLabel
          label="利用者名"
          name="username"
          ref={register({ required: true })}
        />
        <InputWithLabel label="メールアドレス" name="email" ref={register()} />
        <InputWithLabel label="住所" name="address" ref={register()} />
        <InputWithLabel label="利用目的" name="purpose" ref={register()} />
        <InputWithLabel label="電話番号" name="tel" ref={register()} />
        <InputWithLabel
          label="備考"
          name="remarks"
          multiline
          ref={register()}
        />
        <div className="mt-4">
          <div className="font-bold">{t`お支払い方法`}</div>
          <div className="ml-4">
            <label>
              <input
                type="radio"
                name="paymentMethod"
                value="CASH"
                ref={register()}
              />
              <span className="ml-2">現金</span>
            </label>
          </div>
          <div className="ml-4">
            <label>
              <input
                type="radio"
                name="paymentMethod"
                value="BANK"
                ref={register()}
              />
              <span className="ml-2">銀行振込</span>
            </label>
          </div>
        </div>
        <div className="font-bold">{t`利用設備`}</div>
        {equipments.map((equipment) => (
          <div key={equipment.id}>
            <label className="ml-4">
              <input
                value={equipment.id}
                type="checkbox"
                name={`equipmentIds[]`}
                ref={register}
              />
              <span className="ml-2">{equipment.name}</span>
            </label>
          </div>
        ))}
        <div className="mt-3">
          <label>
            <input ref={register()} name="isDeleted" type="checkbox" />
            <span className="ml-2">キャンセル済にする</span>
          </label>
        </div>

        <div className="my-8 text-center">
          <Button type="submit">
            {updating ? t`送信中...` : t`予約情報を更新する`}
          </Button>
        </div>
      </form>
    </div>
  )
}
