import {
  useCallback,
  ReactNode,
  createContext,
  useContext,
  useEffect,
} from 'react'
import { useLocalStorageState } from 'ahooks'
import { PaymentMethod } from '../__generated__/globalTypes'

export interface ReservationForm {
  id: string
  dateOfUse: string
  timeSlotId: string
  timeSlotName: string
  equipmentIds: string[]
}

const initialState = {
  reservations: [] as ReservationForm[],
  user: {
    email: '',
    group: '',
    paymentMethod: 'BANK' as PaymentMethod,
    purpose: '',
    remarks: '',
    tel: '',
    username: '',
    address: '',
  },
}

type State = typeof initialState

type ContextType = {
  state: State
  setState: (s: Partial<State>) => void
}

const Context = createContext<ContextType>(null!)

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, _setState] = useLocalStorageState('appState', initialState)

  const setState = useCallback(
    (next: Partial<State>) => {
      _setState((state) => ({
        ...state,
        ...next,
      }))
    },
    [_setState],
  )

  useEffect(() => {
    // Support migration from the old cache.
    if (state.user.paymentMethod === 'CASH') {
      setState({
        user: { ...state.user, paymentMethod: 'BANK' as PaymentMethod },
      })
    }
  }, [setState, state.user])

  return (
    <Context.Provider value={{ state, setState }}>{children}</Context.Provider>
  )
}

export const useAppContext = () => {
  return useContext(Context)
}
