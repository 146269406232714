import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}

firebase.initializeApp(firebaseConfig)

// To debug purpose
firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    Object.assign(window, {
      __firebase_token__: await firebase.auth().currentUser?.getIdToken(),
    })
  }
})
