import { useTitle } from 'ahooks'
import { Header } from 'src/components/Header'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Calendar } from './calendar'
import { Input } from './input'
import { Confirm } from './confirm'
import { Thanks } from './thanks'

export function Reservation() {
  const title = process.env.REACT_APP_APP_NAME || 'Reservation System'
  useTitle(title)

  return (
    <div>
      <Header title={title} />
      <Switch>
        <Route exact path="/" component={Calendar} />
        <Redirect exact path="/reservations" to="/reservations/calendar" />
        <Route path="/reservations/calendar" component={Calendar} />
        <Route path="/reservations/input" component={Input} />
        <Route path="/reservations/confirm" component={Confirm} />
        <Route path="/reservations/thanks/:emailHistoryId" component={Thanks} />
      </Switch>
    </div>
  )
}
