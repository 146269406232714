import { gql } from 'urql'
import { Button } from 'src/components/Button'
import clsx from 'clsx'
import { Pagination } from 'src/components/Pagination'
import { CalendarHead } from 'src/components/CalendarHead'
import { Closed } from 'src/components/Closed'
import {
  DATE_FORMAT,
  getWeekdayColorClass,
  getWeekdayFromNumber,
} from 'src/shared/util'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import {
  useBlockTimeSlotsMutation,
  useGetReservationListByAdminQuery,
} from 'src/__generated__/gql'
import { useCalendarize } from 'src/hooks/useCalendarize'
import { toast } from 'react-hot-toast'

gql`
  query GetReservationListByAdmin($year: Int!, $month: Int!) {
    reservations(year: $year, month: $month) {
      createdAt
      dateOfUse
      deletedAt
      email
      equipments {
        id
        name
      }
      group
      id
      dateOfUse
      paymentMethod
      purpose
      isDeleted
      remarks
      tel
      timeSlot {
        id
        name
      }
      username
    }
    timeSlots {
      id
      name
    }
  }
`

gql`
  mutation BlockTimeSlots($input: [BlockReservationInput!]!) {
    blockTimeSlots(input: $input)
  }
`

export function Reservations() {
  const {
    currentMonth,
    weeks,
    prevMonth,
    nextMonth,
    month,
    year,
  } = useCalendarize()
  const [{ data, fetching }, refresh] = useGetReservationListByAdminQuery({
    variables: { year, month },
  })

  const { register, handleSubmit } = useForm({
    defaultValues: { dates: [] as string[] },
  })

  const [{ fetching: blocking }, block] = useBlockTimeSlotsMutation()

  const getReservation = useCallback(
    (date: number, timeSlotId: string) => {
      return data?.reservations.find((r) => {
        return (
          dayjs(r.dateOfUse).format(DATE_FORMAT) ===
            currentMonth.date(date).format(DATE_FORMAT) &&
          r.timeSlot.id === timeSlotId
        )
      })
    },
    [data, currentMonth],
  )

  const onSubmit = handleSubmit(async (_input) => {
    const input = _input.dates.map((date) => {
      const [dateNum, timeSlotId] = date.split(',')
      return {
        dateOfUse: currentMonth.date(Number(dateNum)).format(DATE_FORMAT),
        timeSlotId,
      }
    })
    const { error } = await block({ input })
    if (!error) {
      toast.success('ブロックしました')
      refresh()
    }
  })

  return (
    <div>
      <div className="flex justify-center">
        <Button onClick={() => refresh()} disabled={fetching}>
          {fetching ? '読み込み中...' : '最新の情報に更新する'}
        </Button>
      </div>

      <div className="my-6 mx-6">
        <Pagination onPrev={prevMonth} onNext={nextMonth} />
      </div>

      <div className="my-4 text-center">{currentMonth.format('YYYY/MM')}</div>

      <form onSubmit={onSubmit} className="overflow-x-auto">
        <table className="min-w-max sm:w-full border border-solid ">
          <CalendarHead />
          <tbody>
            {weeks.map((week) => (
              <tr key={String(week)}>
                {week.map((date, i) => (
                  <td
                    key={i}
                    className={clsx(
                      'p-3 border boder-solid',
                      getWeekdayColorClass(getWeekdayFromNumber(i)),
                    )}
                  >
                    {date > 0 && (
                      <>
                        <div>{date}</div>
                        {data?.timeSlots.map((timeSlot) => {
                          const r = getReservation(date, timeSlot.id)

                          return (
                            <div key={timeSlot.id}>
                              <label>
                                {r && !r.isDeleted ? (
                                  <Closed />
                                ) : (
                                  <input
                                    name="dates[]"
                                    type="checkbox"
                                    value={[String(date), timeSlot.id]}
                                    ref={register}
                                  />
                                )}
                                <span className="ml-3">{timeSlot.name}</span>
                              </label>
                              {r && (
                                <span className="ml-3 text-blue-400">
                                  <Link to={`/admin/reservations/${r.id}`}>
                                    {r.group || r.username || r.purpose}
                                  </Link>
                                </span>
                              )}
                            </div>
                          )
                        })}
                      </>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-center my-8">
          <Button type="submit">
            {blocking ? `送信中...` : t`選択したコマをブロックする`}
          </Button>
        </div>
      </form>
    </div>
  )
}
