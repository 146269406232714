import { useAuth } from 'src/hooks/useAuth'
import { t } from 'ttag'
import { Button } from 'src/components/Button'
import { InputWithLabel } from 'src/components/InputWithLabel'
import { useForm } from 'react-hook-form'

export function Login() {
  const { signIn, error, loading } = useAuth()

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = handleSubmit((input) => {
    signIn(input.email, input.password)
  })

  return (
    <div>
      <form onSubmit={onSubmit} className="w-64 m-auto">
        {!loading && error && <div>{error}</div>}
        <InputWithLabel
          label="Email"
          name="email"
          type="email"
          ref={register({ required: true })}
        />
        <InputWithLabel
          label="Password"
          name="password"
          type="password"
          ref={register({ required: true })}
        />
        <div className="text-center mt-10">
          <Button type="submit">
            {loading ? t`読み込み中...` : t`ログイン`}
          </Button>
        </div>
      </form>
    </div>
  )
}
