import { retryExchange } from '@urql/exchange-retry'
import {
  Exchange,
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
} from 'urql'
import { pipe, tap, mergeMap, fromPromise, map } from 'wonka'
import firebase from 'firebase/app'
import { toast } from 'react-hot-toast'

const errorExchange: Exchange = ({ forward }) => (ops$) => {
  return pipe(
    forward(ops$),
    tap(({ error, operation }) => {
      if (error) {
        console.error(`GraphQL Error: ${error.message}`, {
          ...operation,
          error,
        })
        toast.error(error.message.replace('[GraphQL]', ''))
      }
    }),
  )
}

const headerExchange: Exchange = ({ forward }) => (operations$) =>
  pipe(
    operations$,
    mergeMap((operation) => {
      const currentUser = firebase.auth().currentUser
      if (!currentUser) {
        return pipe(
          fromPromise(Promise.resolve(null)),
          map(() => operation),
        )
      }
      return pipe(
        fromPromise(currentUser.getIdToken()),
        map((authToken) => {
          if (!operation.context.fetchOptions) {
            operation.context.fetchOptions = {}
          }
          Object.assign(operation.context.fetchOptions, {
            headers: {
              accept: 'application/json',
              'x-auth-token': authToken,
            },
          })
          return operation
        }),
      )
    }),
    forward,
  )

const exchanges = [
  dedupExchange,
  cacheExchange,
  headerExchange,
  retryExchange({
    maxNumberAttempts: 3,
    maxDelayMs: 8000,
  }),
  errorExchange,
  fetchExchange,
]

export const urqlClient = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL!,
  exchanges,
  requestPolicy: 'cache-and-network',
  // FIXME: suspense: true breaks HMR
  // suspense: true,
})
