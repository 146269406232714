import './services/firebase'

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider as UrqlProvider } from 'urql'
import { Reservation } from './pages/reservations'
import { Admin } from './pages/admin'
import { urqlClient } from './services/urqlClient'
import { AppContextProvider } from './AppContext'
import { Toaster } from 'react-hot-toast'

function App() {
  return (
    <React.Suspense fallback={'loading...'}>
      <AppContextProvider>
        <UrqlProvider value={urqlClient}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Reservation} />
              <Route path="/admin" component={Admin} />
              <Route path="/reservations" component={Reservation} />
            </Switch>
          </BrowserRouter>
        </UrqlProvider>
      </AppContextProvider>
      <Toaster />
    </React.Suspense>
  )
}

export default App
