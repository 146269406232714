import { t } from 'ttag'
import { Oval } from 'react-loader-spinner'

export function Loading() {
  return (
    <div className="flex flex-col items-center justify-center h-40">
      <Oval
        width={30}
        color="#0369a1"
        secondaryColor="#7dd3fc"
        strokeWidth={5}
      />
      <div>{t`読み込み中...`}</div>
    </div>
  )
}
