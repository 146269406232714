import { t } from 'ttag'
import dayjs from 'dayjs'

export const weekdays = [
  `Sun`,
  `Mon`,
  `Tue`,
  `Wed`,
  `Thu`,
  `Fri`,
  `Sat`,
] as const

type Weekday = typeof weekdays[number]

export const weekdayDict = new Map<Weekday, string>([
  ['Sun', t`日`],
  ['Mon', t`月`],
  ['Tue', t`火`],
  ['Wed', t`水`],
  ['Thu', t`木`],
  ['Fri', t`金`],
  ['Sat', t`土`],
])

export const weekdayColorDict = new Map<Weekday, string>([
  ['Sun', 'bg-red-100'],
  ['Sat', 'bg-blue-100'],
])

export function getWeekdayFromNumber(n: number): Weekday {
  return weekdays[n]
}

export function getWeekdayString(d: Weekday) {
  return weekdayDict.get(d)
}

export function getWeekdayColorClass(d: Weekday) {
  return weekdayColorDict.get(d) || 'bg-white'
}

export const equipmentTypeMap = new Map<RS.EquipmentType, string>([
  ['BY_DATE', t`日`],
  ['BY_TIME_SLOT', t`コマ`],
])

export const paymentMethodMap = new Map<RS.PaymentMethod, string>([
  ['CASH', t`現金`],
  ['BANK', t`銀行振込`],
])

export const DATE_FORMAT = 'YYYY-MM-DD'

export function formatDateForHuman(date: string) {
  return dayjs(date).format('YYYY年M月D日')
}
